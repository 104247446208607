<template>
    <div class="youtube-wrapper position-relative" v-if="!isMobile()">
        <div class="youtube-video py-4 bg-black">
            <video autoplay muted loop>
                <source src="https://tt.itmo.ru/docs/highpark.mp4" type="video/mp4">
            </video>
        </div>
    </div>

    <section class="main-intro my-5 mb-sm-0 ps-md-5 position-relative">
        <div class="main-intro__itmo pt-5">Центр трансфера технологий Университета ИТМО</div>

        <div class="main-intro__accent position-absolute"></div>

        <h1 class="main-intro__title pt-4 position-relative">
            {{ getHeaderTitle }}
        </h1>

        <div class="d-flex flex-column pt-3 pt-md-5">
            <Tabs :items="mainTabItems">
                <template #content>
                    <div
                        v-for="(item, index) in getHeaderTabsItems(1)"
                        :key="index"
                        class="main-intro__GOAL tab-pane fade show active mt-3 ms-0 ms-sm-4" id="goal" role="tabpanel"
                        aria-labelledby="goal-tab"
                        v-html="item"
                    />

                    <div class="tab-pane fade ms-4" id="tech-focus" role="tabpanel" aria-labelledby="tech-focus-tab">
                        <div class="main-intro__list list-categories d-flex flex-column mt-3">
                            <div
                                v-for="(item, index) in getHeaderTabsItems(2)"
                                :key="index"
                                class="list-categories__item d-flex align-items-center mt-2"
                            >
                                <div><img src="../../assets/views/main-page/blue-circle.svg" alt=""></div>
                                <div class="ms-3">{{ item }}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </Tabs>
        </div>
    </section>

    <section class="main-cards my-5 py-5" v-if="services.colorful || services.basic">
        <h2 class="main-events__title">Услуги</h2>
        
        <div class="colorful-cards-wrapper mt-4">
            <a
                v-for="(service, colorfulIndex) in services.colorful" :key="colorfulIndex"

                :class="`service-card ${getColorfulServiceCardClass(service.service_type)} text-decoration-none`"

                :href="service.link"
                target="_blank"
            >
                <div class="service-card-title">
                    {{ service.title }}
                </div>

                <div class="service-card-body mt-3" v-html="service.description" />

                <div class="d-flex align-items-end justify-content-end mt-auto">
                    <a v-show="service.link" :href="service.link" target="_blank" class="service-card__link" />
                </div>
            </a>
        </div>

        <div class="basic-cards-wrapper mt-4">
            <a
                v-for="(service, basicIndex) in services.basic" :key="basicIndex"

                :class="`service-card ${getBasicServiceCardClass(service.service_type)} text-decoration-none`"

                :href="service.link"
                target="_blank"
            >
                <div class="service-card-title">
                    {{ service.title }}
                </div>

                <div class="d-flex align-items-end justify-content-end mt-auto">
                    <a v-show="service.link" :href="service.link" target="_blank" class="service-card__link" />
                </div>
            </a>
        </div>
    </section>

    <section class="main-events my-5 pt-5 pt-sm-0">
        <div class="d-flex flex-column align-items-start flex-sm-row my-3 mt-5">
            <h2 class="main-events__title">Каталог разработок</h2>
            <div class="main-events__more-news d-flex align-items-center ms-sm-auto mt-2 mt-sm-0">
                <a href="/developments">Перейти</a>
                <img class="ms-3" src="../../assets/views/main-page/arrow-aside.svg" alt="">
            </div>
        </div>

        <div class="d-none d-md-block mb-3">
            <div class="main-events__other-events-grid mt-5">
                <a
                    class="d-flex main-events__event event text-decoration-none"
                    v-for="(news, index) in randomProducts" :key="index"
                    :href="`/developments/${news.id}`"
                    :data-redirect="`${news.has_redirect}`"
                >
                    <div class="event__image w-50">
                        <img v-if="news.image" :src="news.image" alt="" class="random-product-image">
                    </div>
                    <div class="main-events__event-block d-flex flex-column ms-4">
                        <div class="event__title">
                            <span
                                class="text-black"
                            >
                                {{ news.title }}
                            </span>
                        </div>
                        <div class="event__description mt-2 text-black">
                            {{ news.short_description }}
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="d-flex flex-column align-items-start flex-sm-row my-3 mt-5">
            <h2 class="main-events__title">Последние события</h2>
            <div class="main-events__more-news d-flex align-items-center ms-sm-auto mt-2 mt-sm-0">
                <a href="/news">Больше новостей</a>
                <img class="ms-3" src="../../assets/views/main-page/arrow-aside.svg" alt="">
            </div>
        </div>

        <div class="main-events__main-events-grid mt-4 mt-md-0">
            <carousel :items-to-show="itemsToShow">
                <slide
                    v-for="(news, index) in mainNews"
                    :key="index"
                >
                    <a
                        class="main-event d-flex flex-column pb-4 text-white"
                        :href="news.has_redirect ? news.url : `/news/${news.id}`"
                        :target="news.has_redirect ? '_blank' : ''"
                    >
                        <img
                            class="main-event__image position-absolute"
                            :src="news.image"
                            alt=""
                            style="object-position: center; object-fit: cover; width: 100%; height: 250px;"
                        >

                        <div class="main-event__overlay"></div>

                        <div class="main-event__info">
                            <div class="main-event__title mt-auto" style="font-weight: bold">
                                {{ news.title }}
                            </div>
                            <div class="main-event__description mt-3">
                                {{ news.short_description }}
                            </div>
                        </div>
                    </a>
                </slide>

                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
        </div>
    </section>

    <div class="toast align-items-center text-white bg-primary border-0" role="alert" aria-live="assertive"
         aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body">
                Ваша заявка успешно отравлена!
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                    aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, computed} from "vue";
import { useRouter } from "vue-router";
import Tabs from "@/components/common/Tabs/Tabs";
import { useStore } from "vuex";
import routerMixin from "@/mixins/routerMixin";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: "Main",

    components: {
        Tabs, 
        Carousel, Slide, Pagination, Navigation
    },

    setup() {
        const router = useRouter()
        const store = useStore()

        const iframeLink = computed(() => store.getters['config/iframeLink'])
        const mainPageWidgets = computed(() => store.getters['widgets/mainPageWidgets'])
        const mainNews = computed(() => store.getters['news/mainNews'])
        const subNews = computed(() => store.getters['news/subNews'])
        const partnershipAgreementWidgets = computed(() => store.getters['widgets/partnershipAgreementWidgets'])
        const headerTabs = computed(() => store.getters['header/headerTabs'])
        const randomProducts = computed(() => store.getters['products/randomProducts'])
        const services = computed(() => store.getters['widgets/services'])

        // const getHeaderTabText = (id) => {
        //     if (headerTabs.value) {
        //         return headerTabs.value[0]?.items[id].text;
        //     }
        //
        //     return ''
        // }

        // TODO FIX THIS
        const mainTabItems = [
            {
                label: 'Цель ЦТТ',
                tabId: 'goal',
                active: true
            },
            {
                label: 'Технологические фокусировки',
                tabId: 'tech-focus',
                active: false
            },
        ]

        const quotesTabItems = ref([
            {
                tabId: 'quote1',
                active: true
            },
            {
                tabId: 'quote2',
                active: false
            },
        ])

        const getQuoteType = computed(() => {
            const activeTab = quotesTabItems.value.find(q => q.active)

            switch (activeTab.tabId) {
                case 'quote1':
                    return 'Научная деятельность'
                default:
                    return 'Проектная деятельность'
            }
        })

        const isProjectsActivity = computed(() => {
            const activeTab = quotesTabItems.value.find(q => q.active)

            switch (activeTab.tabId) {
                case 'quote1':
                    return true
                default:
                    return false
            }
        })

        const getHeaderTitle = computed(() => {
            if (headerTabs.value[0]) {
                return headerTabs.value[0].title
            }

            return ''
        })

        const itemsToShow = computed(() => {
            if (window.innerWidth < 800) {
                return 1
            }

            return 3
        })

        store.dispatch('news/getNews')

        Promise.allSettled([
            store.dispatch('widgets/getMainPageWidgets'),
            store.dispatch('widgets/getPartnershipAgreementWidgets'),
            store.dispatch('widgets/getServices'),

            store.dispatch('partners/getPartners'),

            store.dispatch('products/getProducts'),

            store.dispatch('header/getHeaderTabs'),

            store.dispatch('news/getNews'), // main

            store.dispatch('newsCategories/getNewsCategories'),

            store.dispatch('products/getRandomProducts')
        ])


        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        const { goToPage, isMenuVisible, toggleMenu } = routerMixin();

        const selectTab = (activeTab) => {
            const { tabId } = JSON.parse(JSON.stringify(activeTab));

            quotesTabItems.value.forEach(q => {
                q.active = q.tabId === tabId;
            })
        }

        const goToDevelopmetsPage = (filter) => {
            if (typeof filter === 'string') {
                router.push({ name: 'Developments', query: { filter } })
            } else {
                router.push({ name: 'Developments' })
            }
        }

        const getImageUrl = (imageUrl) => {
            return `/${imageUrl.split('/').slice(3).join('/')}`
        }

        const getAgreementFile = computed(() => {
            if (partnershipAgreementWidgets.value) {
                return `/${partnershipAgreementWidgets.value.agreement_file.split('/').slice(3).join('/')}`
            }

            return ''
        })

        const goToTechnologyBrokerSchoolLink = () => {
            const url = document.createElement('a');
            url.href = String(getTechnologyBrokerSchoolLink.value);
            url.target = '_blank';
            const event = new MouseEvent('click', {
                'view': window,
                'bubbles': false,
                'cancelable': true
            });
            url.dispatchEvent(event);
        }

        const isMobile = () => window.innerWidth < 1199

        // Виджет Центра трансфера технологий университета ИТМО
        const getCttLink = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.ctt.report_file
            }

            return ''
        })
        const getCttTitle = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.ctt.title
            }

            return ''
        })
        const getCttDescription = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.ctt.description
            }

            return ''
        })

        // Виджет Образовательная деятельность
        const getEducationalActivityInfo = (key) => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.educational_activity[key]
            }

            return ''
        }

        // Виджет ITMO.FAMILY Foundation
        const getItmoFundLink = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.itmo_family_foundation.redirect_url
            }

            return ''
        })
        const getItmoFundTitle = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.itmo_family_foundation.title
            }

            return ''
        })
        const getItmoFundDescription = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.itmo_family_foundation.description
            }

            return ''
        })

        // Виджет Партнерство
        const getPartnershipTitle = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.partnership.title
            }

            return ''
        })

        // Виджет Разработки Университета ИТМО
        const getProductsTitle = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.products.title
            }

            return ''
        })

        // Виджет Научная деятельность
        const getScientificActivityTabs = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.scientific_activity.tabs
            }

            return ''
        })
        const getScientificActivityTabInfo = (id, key) => {
            if (getScientificActivityTabs.value) {
                return getScientificActivityTabs.value.find(item => item.id === id)[key];
            }

            return ''
        }

        // Виджет "Школа Технических брокеров"
        const getTechnologyBrokerSchoolLink = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.technology_broker_school.redirect_url
            }

            return ''
        })
        const getTechnologyBrokerSchoolTitle = computed(() => {
            if (mainPageWidgets.value) {
                return mainPageWidgets.value.technology_broker_school.title
            }

            return ''
        })

        const getHeaderTabsItems = (order) => {
            if (headerTabs.value) {
                return headerTabs.value[0]?.items.find(item => item.order === order).points_split
            }
        }

        const getColorfulServiceCardClass = (serviceType) => {
            const classes = {
                B: 'service-card--blue',
                O: 'service-card--orange',
                Y: 'service-card--yellow',
                D: 'service-card--black',
            }

            return classes[serviceType]
        }

        const getBasicServiceCardClass = (serviceType) => {
            const classes = {
                '1': 'service-card--type-1',
                '2': 'service-card--type-2',
                '3': 'service-card--type-3',
                '4': 'service-card--type-4',
                '5': 'service-card--type-5'
            }

            return classes[serviceType]
        }

        return {
            mainTabItems,
            quotesTabItems,
            getQuoteType,
            iframeLink,
            mainPageWidgets,
            mainNews,
            subNews,
            randomProducts,
            services,
            itemsToShow,
            getAgreementFile,
            getItmoFundLink,
            getTechnologyBrokerSchoolLink,
            getCttLink,
            getHeaderTitle,
            getCttTitle,
            getCttDescription,
            getItmoFundTitle,
            getItmoFundDescription,
            getPartnershipTitle,
            getProductsTitle,
            getTechnologyBrokerSchoolTitle,
            isProjectsActivity,
            getHeaderTabsItems,
            getEducationalActivityInfo,
            getScientificActivityTabInfo,
            goToTechnologyBrokerSchoolLink,
            goToPage,
            selectTab,
            getImageUrl,
            goToDevelopmetsPage,
            isMobile,
            getColorfulServiceCardClass,
            getBasicServiceCardClass,
        }
    }
}
</script>
