<template>
    <ul
        v-if="type === 'text'"
        :class="$attrs.class"
        class="nav nav-tabs"
        role="tablist"
    >
        <li
            v-for="item in items"
            :key="item.tabId"
            class="nav-item"
            role="presentation"
        >
            <button
                class="tab-btn nav-link"
                :class="{ 'active': item.active }"
                :id="`${item.tabId}-tab`"
                data-bs-toggle="tab"
                :data-bs-target="`#${item.tabId}`"
                type="button"
                role="tab"
                :aria-controls="item.tabId"
                aria-selected="true"
            >
                <span class="tab-label">{{ item.label }}</span>
            </button>
        </li>
    </ul>

    <div
        v-if="type === 'dots'"
        class="nav nav-pills d-flex align-items-center"
        :class="$attrs.class"
        role="tablist"
    >
        <div
            v-for="(item, index) in items"
            :key="item.tabId"
            role="presentation"
            class="nav-item tab-dot"
            data-bs-toggle="tab"
            :data-bs-target="`#${item.tabId}`"
            :aria-controls="item.tabId"
            :aria-selected="item.active"
            @click="selectTab(index)"
        >
            <img
                v-if="item.active"
                src="./icons/active.svg" alt=""
                width="45"
                height="44"
                role="tab"
            >

            <img
                v-else
                src="./icons/inactive.svg" alt=""
                width="13"
                height="12"
                role="tab"
            >
        </div>
    </div>

    <ul
        v-if="type === 'filled-text'"
        class="tab-btn-filled__list d-flex flex-column flex-md-row nav nav-pills mb-3"
        role="tablist"
    >
        <li
            class="nav-item tab-btn-filled"
            role="presentation"
            v-for="(item, index) in items"
            :key="item.tabId"
            @click="selectTab(index)"
        >
            <button
                class="nav-link"
                :class="{
                    'active': item.active,
                    'tab-btn-filled--active': item.active,
                    'tab-btn-filled--inactive': !item.active
                }"
                :id="`${item.tabId}-tab`"
                data-bs-toggle="pill"
                :data-bs-target="`#${item.tabId}`"
                type="button"
                role="tab"
                :aria-controls="item.tabId"
                aria-selected="true"
            >
                {{ item.label }}
            </button>
        </li>
    </ul>

    <div class="tab-content" :class="{ 'mt-3': type === 'dots'}">
        <slot name="content"></slot>
    </div>
</template>

<script>
import { ACCEPTABLE_TYPES } from "@/components/common/Tabs/constants";
import {onMounted, toRefs, ref} from "vue";

export default {
    name: "Tabs",

    props: {
        type: {
            type: String,
            default: 'text',
            validator(value) {
                return ACCEPTABLE_TYPES.includes(value)
            }
        },

        items: {
            type: Array,
            required: true
        }
    },

    setup(props, { emit }) {
        let modifiedItems = ref([]);

        // watch(props, (newValue) => {
        //     console.log('newValue = ', newValue)
        //     const thatVal = validatePropItems()
        //     if (!thatVal) {
        //         console.error('Tabs: Invalid prop items');
        //     }
        // }, {
        //     immediate:true
        // });

        onMounted(() => {
            const { items } = toRefs(props);
            modifiedItems.value = [...items.value]
        })

        const isItemActive = (item) => {
            console.log(item)
        };

        const selectTab = (index) => {
            const activeItem = modifiedItems.value.find((i) => i.active)
            activeItem.active = false

            modifiedItems.value[index].active = true

            emit('select', modifiedItems.value[index])
        }

        /* TODO дописать валидацию */
        // const validatePropItems = () => {
        //     console.log('HEY validatePropItems!')
        //     const { item } = toRefs(props)
        //     console.log('item = ', item)
        //
        //     item.forEach(() => {
        //         if (typeof item !== 'object') return false;
        //
        //         if (this.type === 'text') {
        //             if (!item.label) return false;
        //             if (typeof item.label !== 'string') return false;
        //         }
        //
        //         if (!item.tabId) return false;
        //         if (typeof item.tabId !== 'string') return false;
        //
        //         if (!item.active) return false;
        //         if (typeof item.active !== 'boolean') return false;
        //
        //         return undefined;
        //     });
        //
        //     return true;
        // }

        return {
            isItemActive,
            selectTab
        }
    }
}
</script>
